class Debounce {
    duration;
    timeout = null;

    constructor(duration) {
        this.duration = duration;
    }

    cancel = () => {
        window.clearTimeout(this.timeout);
    }

    call = (callback = () => { }) => {
        this.cancel();
        this.timeout = setTimeout(() => {
            callback();
        }, this.duration);
    }
}


const callDebounce = (duration) => {
    return new Debounce(duration);
}

export default callDebounce;
