class ClipBoard {
    copy(text) {
        return new Promise(async (resolve, reject) => {
            try {
                await navigator.clipboard.writeText(text)
                resolve()
            } catch (e) {
                console.log("Clipboard/copy", e)
                if (this.fallbackCopy(text)) {
                    resolve()
                } else {
                    reject()
                }
            }
        })
    }

    fallbackCopy(text) {
        var textArea = document.createElement("textarea");

        textArea.value = text;
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        let success = false
        try {
            var successful = document.execCommand('copy');
            success = !!successful;
        } catch (err) {
            console.error('Clipboad/fallbackCopy', err);
        }

        document.body.removeChild(textArea);
        return success
    }
}

const cboard = new ClipBoard()

export default cboard
