import axios from "axios"

export const frameIOApi = async (endPoint, token) => {
    try {
        if (!token) {
            return { ok: 0, message: "Unathorized" }
        }

        const response = await axios.get(
            `https://api.frame.io/v2${endPoint}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        )

        if (response.status === 200) {
            return { ok: 1, response, message: "Folder found" }
        } else {
            return { ok: 0, message: "Destination Folder doesn't exist. Please select another destination" }
        }
    } catch (e) {
        return { ok: 0, message: e.response?.status >= 400 ? "Destination Folder doesn't exist. Please select another destination" : "Something went wrong" }
    }
}

export const createFrameioAsset = async (rootAssetId, token, { payload = {}, signal } = {}) => {
    try {
        if (!token) {
            return { ok: 0, message: "Unathorized" }
        }

        const response = await axios.post(
            `https://api.frame.io/v2/assets/${rootAssetId}/children`, payload, {
            signal,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        if (response.status === 200) {
            return response.data
        } else {
            return {}
        }
    } catch (e) {
        console.log("frameio/createFrameioAsset", e)
        return {}
    }
}

export const uploadToFrameio = async (file, assetId, { token, signal, onProgress } = {}) => {
    await axios.put(`https://api.frame.io/v2/assets/${assetId}`, file, {
        signal,
        onUploadProgress: (event) => {
            onProgress(event)
        },
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": file.type
        }
    })
}

export const uploadToClound = async (file, urls, { signal, onProgress = () => { }, fileType = '' } = {}) => {
    return new Promise(async (resolve, reject) => {
        try {
            let totalUploadedSize = 0;

            if (urls.length > 0) {
                const chunkSize = file.size / urls.length

                for (let i = 0; i < urls.length; i++) {
                    const url = urls[i]
                    const offset = i * chunkSize
                    const chunk = urls.length > 1 ? file.slice(offset, offset + chunkSize) : file
                    let chunkUploadedSize = 0

                    const headers = {
                        'Content-Type': fileType,
                        'x-amz-acl': 'private'
                    }

                    await axios.put(url, chunk, {
                        signal,
                        onUploadProgress: (event) => {
                            chunkUploadedSize = event.loaded
                            const currentUploadSize = totalUploadedSize + chunkUploadedSize
                            const totalPercentUploaded = Math.floor((currentUploadSize / file.size) * 100)
                            onProgress({ percentage: totalPercentUploaded, loaded: currentUploadSize })
                        },
                        headers
                    })

                    totalUploadedSize += chunkUploadedSize
                }

                resolve()
            } else {
                throw new Error("No upload URLs provided")
            }
        } catch (e) {
            reject(e)
            console.log("utils/frameio/uploadToClound", e)
        }
    })
}

// const getFrameioAsset = async (assetId, token) => {
//     const query = new URLSearchParams({
//         include_deleted: 'true',
//         type: 'file'
//     }).toString();

//     try {
//         const resp = await fetch(
//             `https://api.frame.io/v2/assets/${assetId}?${query}`,
//             {
//                 method: 'GET',
//                 headers: {
//                     Authorization: `Bearer ${token}`
//                 }
//             }
//         );

//         return await resp.json()
//     } catch (e) {
//         console.log("frameio/getFrameioAsset", e)
//         return {}
//     }
// }

export const deleteFrameioAsset = async (assetId, token) => {
    try {
        if (!token) {
            return { ok: 0, message: "Unathorized" }
        }

        const response = await fetch(
            `https://api.frame.io/v2/assets/${assetId}`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        if (response.status === 200) {
            return await response.json()
        } else {
            return {}
        }
    } catch (e) {
        console.log("frameio/deleteFrameioAsset", e)
        return {}
    }
}


export const getFrameioFolderPath = (folderId, assetInfo) => {
    const look = (asset, path = "") => {
        path = path + "/" + asset.title;
        if (
            (asset.root_asset_id && asset.root_asset_id === folderId) ||
            asset.id === folderId
        ) {
            return path;
        }

        if (asset.children?.length <= 0) {
            return false;
        }

        for (let i = 0; i < asset.children?.length; i++) {
            const _path = look(asset.children[i], path);
            if (_path) {
                return _path;
            }
        }

        return false;
    };

    for (let i = 0; i < assetInfo.length; i++) {
        const path = look(assetInfo[i], "");
        if (path) {
            return path;
        }
    }

    return folderId;
};
