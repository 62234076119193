import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';

import Home from './pages/home';

import SuspenseLoader from './components/shared/SuspenseLoader';

import { useDispatch } from './store';
import { setInitialData, setUserData } from './store/actions';
import { postMessageToParent } from './utils/parent';

import "./styles/fonts.scss"
import "./styles/globals.scss"
import "./styles/mixins.scss"
import "./styles/variables.scss"

const UploadFiles = React.lazy(() => import('./pages/upload-files'));
const LoginPage = React.lazy(() => import("./pages/login"))
const LoginRedirect = React.lazy(() => import('./pages/loginRedirect'));
const LinkExpaired = React.lazy(() => import('./pages/link'));
const ActivatePage = React.lazy(() => import('./pages/activate'));

function App() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  React.useEffect(() => {
    const listener = (event) => {
      try {
        const data = event.data || {}
        switch (data.action) {
          case "login":
            navigate('/login')
            break;

          case 'set_initial_data':
            dispatch(setInitialData(data?.data || {}))
            break;

          case "logout":
            localStorage.clear()
            dispatch(setUserData({}))
            navigate('/')
            postMessageToParent({ action: "logout" })
            break;

          default:
            break;
        }
      } catch (e) {
        console.log("App/useEffect/listener", e)
      }
    }

    window.addEventListener('message', listener, false)
    postMessageToParent({ action: "get_initial_data" })

    return () => {
      window.removeEventListener('message', listener, false)
    }
  }, [])

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/upload/:id" element={(
        <SuspenseLoader>
          <UploadFiles />
        </SuspenseLoader>
      )} />
      <Route path="/upload" element={(
        <SuspenseLoader>
          <UploadFiles />
        </SuspenseLoader>
      )} />
      <Route path="/error" element={
        <SuspenseLoader>
          <LinkExpaired />
        </SuspenseLoader>
      } />
      <Route path='/login' element={(
        <SuspenseLoader>
          <LoginPage />
        </SuspenseLoader>
      )} />
      <Route path="/login_redirect" element={(
        <SuspenseLoader>
          <LoginRedirect />
        </SuspenseLoader>
      )} />

      <Route path="/activate" element={(
        <SuspenseLoader>
          <ActivatePage />
        </SuspenseLoader>
      )} />
    </Routes>
  );
}

export default App;

