import React from 'react'

function Loading() {
  return (
    <div>
      <div>
        <div className="loadingMain">
          <div className="spinner"></div>
        </div>
      </div>
    </div>
  )
}
export default Loading
