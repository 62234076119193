import React from "react";

import axios from "axios";
import { useSelector } from "../../store"
import "./email-preferences.scss"
import { message } from "antd";
import callDebounce from "../../utils/debounce";

const apiBasePath = process.env.REACT_APP_UPLOAD_API_BASEPATH
const debounce = callDebounce(600)

const EmailPreferences = () => {
    const [formData, setFormData] = React.useState({
        business_name: "",
        disable_sender_email_notifications: false,
        disable_receiver_email_notifications: false
    })

    const userData = useSelector((state) => state.userData || {})


    React.useEffect(() => {
        getUserPreferences();
    }, [userData])

    const getUserPreferences = () => {
        if (userData.id) {
            const data = new URLSearchParams()
            data.append('user_id', userData.id);

            axios.get(apiBasePath + '/user/preferences/email/get/' + userData.id).then(({ data = {} }) => {
                if (data.error == 0) {
                    setFormData(data.data);
                }
            }).catch((error) => {
                console.log("Error while fetching user preferences", error)
            });
        }
    }

    const onChange = (event) => {
        const apiPayload = new FormData();

        switch (event.target.name) {
            case 'business_name':
                setFormData((prev) => ({ ...prev, business_name: event.target.value }));
                apiPayload.append("value", event.target.value || "");
                break;
            default:
                setFormData((prev) => ({ ...prev, [event.target.name]: event.target.checked }))
                apiPayload.append("value", event.target.checked ? 1 : 0);
                break;
        }

        if (!userData.id) {
            return
        };

        apiPayload.append("key", event.target.name);
        apiPayload.append("user_id", userData.id);

        debounce.call(() => {
            axios.post(apiBasePath + '/user/preferences/email/update', apiPayload).then(({ data = {} }) => {
                console.log(data)
                if (data.error !== 0) {
                    message.error(data.msg || "Something went wrong.", 3)
                } else {
                    message.success(data.msg || "Updated successfully.", 3)
                }
            }).catch((error) => {
                console.log("Error while updating user preferences", error);
                message.error("Something went wrong. Please try again.", 3);
            });
        });
    }

    return (
        <section className="email-preference-container">
            <div className="email-preferences-form">
                <h2>Email Preferences</h2>
                <form>
                    <ul>
                        <li>
                            <label htmlFor="business_name" className="form-textfield">
                                Replace Frame.io email address with business name:
                                <input type="text" name="business_name" id="business_name" value={formData.business_name} onChange={onChange} />
                            </label>
                        </li>
                        <li>
                            <label className="form-checkbox" htmlFor="disable_sender_email_notifications">
                                <input type="checkbox" name="disable_sender_email_notifications" id="disable_sender_email_notifications" checked={formData.disable_sender_email_notifications} onChange={onChange} />
                                <span className="checkbox-ui" />
                                Disable sender email notifications
                            </label>
                        </li>
                        <li>
                            <label className="form-checkbox" htmlFor="disable_receiver_email_notifications">
                                <input type="checkbox" name="disable_receiver_email_notifications" id="disable_receiver_email_notifications" checked={formData.disable_receiver_email_notifications} onChange={onChange} />
                                <span className="checkbox-ui" />
                                Disable receiver email noticiations
                            </label>
                        </li>
                    </ul>
                </form>
            </div>
        </section>
    )
}

export default EmailPreferences
