import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react'
import {
  Button,
  Modal,
  message,
  notification,
  Form,
  Input,
  Tree,
  Switch,
  Space,
} from 'antd'
import {
  CloseCircleOutlined,
  LinkOutlined,
  EditOutlined,
  DeleteOutlined,
  CheckCircleFilled,
  ReloadOutlined,
  DownOutlined,
} from '@ant-design/icons'
import axios from 'axios'
// import * as _ from 'lodash'
import Loading from '../loading/loading'
import { frameIOApi, getFrameioFolderPath } from '../../utils/frameio'
import cboard from '../../utils/clipboard'

// const Context = React.createContext({
//   name: 'Default',
// });

const uploadurl = process.env.REACT_APP_UPLOAD_UI_BASEPATH + '/upload/'

const List = forwardRef((props, ref) => {
  //Modal
  const [userData, setUserData] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [uploadsData, setUploadsData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [selectedUpload, setSelectedUpload] = useState(null)
  const [api, contextHolder] = notification.useNotification()
  const [showUploadEditModal, setShowUploadEditModal] = useState(false)
  const [selectedEditUpload, setSelectedEditUpload] = useState(null)
  const [showDestinationFolderModel, setShowDestinationFolderModel] = useState(
    false,
  )
  // const [projectsList, setProjectsList] = useState([])
  const [treeData, setTreeData] = useState([])
  const [defaultOpenKeys, setDefaultOpenKeys] = useState([]);
  const [treeLoading, setTreeLoading] = useState(null)
  const [selectedFolder, setSelectedFolder] = useState(null)
  const [selectedFolderName, setSelectedFolderName] = useState(null)
  const [selectedObj, setSelectedObj] = useState(null)
  const [urlPath, setUrlPath] = useState(null)
  const [uploadId, setUploadId] = useState(null)
  const [showShareModal, setShowShareModal] = useState(false)
  const [isSwitchEnabled, setIsSwitchEnabled] = useState(false)
  const [createSubfolder, setCreateSubfolder] = useState(false)
  const [copied, setCopied] = useState(false)
  const [form] = Form.useForm()

  useEffect(() => {
    getUploadsDataWithUserId()
  }, [])

  useEffect(() => {
    if (props.user) {
      setUserData(props.user)
    }
  }, [props])

  // Expose the function to the parent component
  useImperativeHandle(ref, () => ({
    getUploadsDataWithUserId: () => {
      getUploadsDataWithUserId()
    },
  }))

  // useImperativeHandle(user, () => ({
  //   console.log(user)
  // }));

  const getUploadsDataWithUserId = () => {
    let userData = localStorage.getItem('siloUserData')
      ? localStorage.getItem('siloUserData')
      : null
    if (userData) {
      setIsLoading(true)
      axios
        .get(
          process.env.REACT_APP_UPLOAD_API_BASEPATH +
          '/uploads/user-list/' +
          userData,
        )
        .then((response) => {
          if (
            response.data &&
            response.data.type &&
            response.data.type == 'success'
          ) {
            setUploadsData(response.data.data)
            setIsLoading(false)
          } else {
            setIsLoading(false)
            // getAccounts()
          }
        })
        .catch((error) => {
          setIsLoading(false)
          console.error('Error:', error)
        })
    }
  }

  const showModal = () => {
    if (!props.isPaidUser) {
      message.error('Please purchase a subscription to proceed.')
    } else {
      setIsModalOpen(true)
    }
  }

  // const handleOk = () => {
  //   setIsModalOpen(false)
  // }

  // const handleCancel = () => {
  //   setIsModalOpen(false)
  // }

  const handleClose = () => {
    setIsModalOpen(false)
    setSelectedUpload(null)
  }

  //Copied Toast
  const handleCopy = (textToCopy) => {
    if (!props.isPaidUser) {
      message.error('Please purchase a subscription to proceed.')
      return
    }

    cboard
      .copy(textToCopy)
      .then(() => {
        message.success('Copied!')
      })
      .catch(() => {
        message.error('Failed to copy!')
      })
  }

  const deleteUpload = () => {
    if (selectedUpload && selectedUpload.id) {
      axios
        .get(
          process.env.REACT_APP_UPLOAD_API_BASEPATH +
          '/uploads/delete-upload/' +
          selectedUpload.id,
        )
        .then((response) => {
          if (
            response.data &&
            response.data.type &&
            response.data.type == 'success'
          ) {
            getUploadsDataWithUserId()
            setIsLoading(false)
            handleClose()
            openNotification('bottomRight')
          } else {
            setIsLoading(false)
            openErrorNotification('bottomRight', response.data?.message)
          }
        })
        .catch((error) => {
          setIsLoading(false)
          openErrorNotification('bottomRight')
          console.error('Error:', error)
        })
    }
  }

  const openNotification = (placement) => {
    api.info({
      message: `Successfully Deleted!`,
      // description: 'Please connect to Frame.IO account.',
      placement,
      className: 'home-list-notification-bar',
      style: {
        width: 400,
      },
      //duration: 100000,
      icon: <CheckCircleFilled className="success-icon" />,
      closeIcon: <CloseCircleOutlined />,
    })
  }

  const openErrorNotification = (placement, message) => {
    api.info({
      message: message || `Something went wrong!`,
      // description: 'Please connect to Frame.IO account.',
      placement,
      className: 'home-list-notification-bar',
      style: {
        width: 400,
      },
      //duration: 10000,
      icon: <CloseCircleOutlined className="success-icon" />,
      closeIcon: <CloseCircleOutlined />,
    })
  }

  // close Upload Edit Modal
  const closeUploadEditModal = () => {
    setShowUploadEditModal(false)
    setSelectedEditUpload(null)
  }

  useEffect(() => {
    // This will run whenever the selectedUpload state changes.
    if (selectedEditUpload) {
      openUploadEditModal()
    }
  }, [selectedEditUpload])

  // open Upload Edit Modal
  const openUploadEditModal = () => {
    if (selectedEditUpload) {
      setSelectedFolder(selectedEditUpload.destination_id)
      setSelectedFolderName(selectedEditUpload.destination_name)
      setUrlPath(selectedEditUpload.url)
      form.setFieldsValue({
        title: selectedEditUpload.title,
        description: selectedEditUpload.description,
        destination_name: selectedEditUpload.destination_name,
      })
    }
    setShowUploadEditModal(true)
  }

  const getSharedProjectIds = async () => {
    // setTreeLoading(true);
    // console.log("Fetching shared project IDs...");

    const token = userData ? userData.refresh_token : null;

    if (!token) {
      setTreeLoading(false);
      return [];
    }

    try {
      const response = await axios.get('https://api.frame.io/v2/projects/shared', {
        params: { accountId: userData.id },
        headers: { Authorization: `Bearer ${token}` },
      });

      const projects = response.data;

      // Ensure projects is an array
      if (Array.isArray(projects)) {
        // Fetch details for each project and collect the results
        const projectData = await Promise.all(projects.map(async (project) => {
          return getCollabrationProject(project.id, {
            teamId: project.team?.id || project.team_id,
            teamName: project.team?.name || ""
          });
        }));

        // Flatten the array of arrays
        return projectData.flat();
      } else {
        console.error('Expected an array of shared projects but received:', projects);
      }
    } catch (error) {
      console.error("Error fetching shared projects:", error);
    } finally {
      // setTreeLoading(false);
    }
    return [];
  };


  const getCollabrationProject = async (projectID, { teamId = '', teamName = '' } = {}) => {
    const token = userData ? userData.refresh_token : null;

    if (token) {
      try {
        const response = await axios.get(`https://api.frame.io/v2/projects/${projectID}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data = response.data;

        if (data) {
          // Return an array containing the single project object
          return [{
            id: teamId,
            root_asset_id: teamId,
            title: teamName,
            key: teamId,
            project_id: "",
            type: "team",
            children: [{
              id: data.id,
              root_asset_id: data.root_asset_id,
              title: data.name,
              key: data.root_asset_id,
              project_id: data.id,
              assets: [],
            }]
          }];
        } else {
          console.error('Expected project data but received:', data);
        }
      } catch (error) {
        console.error('Error fetching project details:', error);
      }
    }
    return [];
  };

  const getTeamsMembers = async () => {
    const token = userData ? userData.refresh_token : null;
    try {
      const response = await axios.get('https://api.frame.io/v2/teams', {
        headers: { Authorization: `Bearer ${token}` },
      });

      const accountIds = [...new Set(response.data.map((team) => team.account_id))];

      // Fetch teams for each accountId
      const teamsData = await Promise.all(
        accountIds.map((id) => getTeams(id, token))
      );

      // Flatten the array and remove any null values
      return teamsData.flat().filter(data => data !== null);
    } catch (error) {
      console.error('Error fetching teams:', error);
      // setError('Error fetching teams');
    } finally {
      // setLoading(false);
    }
    return []; // Ensure this is always an array
  };


  const getTeams = async (accountId, token) => {
    setTreeData([]);
    setTreeLoading(true);

    if (!token) {
      setTreeLoading(false);
      return [];
    }

    try {
      const response = await axios.get(`https://api.frame.io/v2/accounts/${accountId}/teams`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data && response.data.length > 0) {
        const teamsList = response.data;
        const projectsData = await Promise.all(teamsList.map(async (team) => {
          return getProjects(team.id, team.name, token);
        }));

        return projectsData.flat();
      }
    } catch (error) {
      console.error('Error fetching teams:', error);
    } finally {
      setTreeLoading(false);
    }
    return []; // Ensure this is always an array
  };


  const getProjects = async (teamId, teamName, token) => {
    if (!token) {
      return [];
    }

    try {
      const response = await axios.get(`https://api.frame.io/v2/teams/${teamId}/projects`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data) {
        const projects = response.data;
        return [{
          id: teamId,
          root_asset_id: teamId,
          key: teamId,
          assets: [],
          value: teamId,
          projectId: "",
          type: "team",
          title: teamName,
          children: projects.map((item) => ({
            id: item.id,
            root_asset_id: item.root_asset_id,
            title: item.name,
            key: item.root_asset_id,
            type: "folder",
            project_id: item.id,
            assets: [],
          }))
        }];
      }
    } catch (error) {
      console.error('Error fetching projects for team:', error);
    }
    return [];
  };

  const getProjectsData = async () => {
    setTreeLoading(true);
    try {
      const [sharedProjects, regularProjects] = await Promise.all([
        getTeamsMembers(),
        getSharedProjectIds()
      ]);

      if (!Array.isArray(regularProjects)) {
        throw new Error("regularProjects is not an array");
      }

      const defaultOpenKeys = [];

      const allProjects = [...sharedProjects, ...regularProjects].map((treeItem) => {
        defaultOpenKeys.push(treeItem.key)
        return treeItem;
      });

      setTreeData(allProjects);
      setDefaultOpenKeys(defaultOpenKeys)
    } catch (error) {
      console.error('Error fetching projects data:', error);
    } finally {
      setTreeLoading(false);
    }
  };

  const onSelect = (selectedKeys, info) => {
    if (info.node?.type === "team") {
      return;
    }

    if (info.selected) {
      setSelectedObj(info)
      if (
        info &&
        info.selectedNodes &&
        info.selectedNodes[0] &&
        info.selectedNodes[0].title
      ) {
        if (info.selectedNodes[0].id == info.selectedNodes[0].project_id) {
          setUrlPath(info.selectedNodes[0].project_id)
        } else {
          setUrlPath(
            info.selectedNodes[0].project_id + '/' + info.selectedNodes[0].id,
          )
        }
        setSelectedFolderName(getFolderPath(info.selectedNodes))
        setSelectedFolder(selectedKeys[0])
        setShowDestinationFolderModel(false)
      }
    } else {
      if (
        selectedObj &&
        selectedObj.selectedNodes &&
        selectedObj.selectedNodes[0] &&
        selectedObj.selectedNodes[0].title
      ) {
        if (
          selectedObj.selectedNodes[0].id ==
          selectedObj.selectedNodes[0].project_id
        ) {
          setUrlPath(selectedObj.selectedNodes[0].project_id)
        } else {
          setUrlPath(
            selectedObj.selectedNodes[0].project_id +
            '/' +
            selectedObj.selectedNodes[0].id,
          )
        }
        setSelectedFolderName(selectedObj.selectedNodes[0].title)
        setSelectedFolder(selectedObj.selectedNodes[0].key)
        setShowDestinationFolderModel(false)
      }
    }
  }

  const getFolderPath = (selectedKeys) => {
    let folderId = ''
    if (Array.isArray(selectedKeys)) {
      folderId = selectedKeys[0] || ''
      folderId = typeof folderId === 'object' ? folderId.id : folderId
    } else if (typeof selectedKeys == 'object') {
      folderId = selectedKeys?.value || ''
    } else if (typeof selectedKeys == 'string') {
      folderId = selectedKeys
    }

    return getFrameioFolderPath(folderId, treeData)
  }

  useEffect(() => {
    // This effect will trigger whenever selectedFolder or selectedFolderName changes
    if (selectedFolder && selectedFolderName) {
      form.setFieldsValue({
        destination_name: selectedFolderName,
      })
    }
  }, [selectedFolder, selectedFolderName])

  const closeDestinationFolderModel = () => {
    // setUrlPath(null)
    // setSelectedFolderName(null)
    // setSelectedFolder(null)
    // setSelectedObj(null)
    setShowDestinationFolderModel(false)
  }

  const onLoadData = async ({ key, children, expanded }) => {
    if (children && expanded) {
      return
    }
    const updatedTreeData = await updateTreeData(treeData, key)
    setTreeData(updatedTreeData)
  }

  const updateTreeData = async (list, key) => {
    let nodeFound = false
    const updatedList = await Promise.all(
      list.map(async (node) => {
        if (node.key === key && node.type !== 'team') {
          nodeFound = true // Mark the node as found
          var token = userData ? userData.refresh_token : null
          if (token) {
            const response = await axios.get(
              `https://api.frame.io/v2/assets/${key}/children?type=folder`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            )

            if (response.data) {
              let children = []
              if (response.data.length > 0) {
                response.data.forEach((res) => {
                  children.push({
                    title: res.name,
                    id: res.id,
                    key: res.id,
                    project_id: node.project_id,
                  })
                })
              }

              return {
                ...node,
                children,
              }
            }
          }
        }

        if (node.children) {
          // If the node is already found, return the original node
          if (nodeFound) {
            return node
          }

          const updatedChildren = await updateTreeData(node.children, key)
          return {
            ...node,
            children: updatedChildren,
          }
        }

        return node
      }),
    )

    return updatedList
  }

  // update Upload Data
  const updateUploadData = async () => {
    if (!props.isPaidUser) {
      message.error('Please purchase a subscription to proceed.')
      return
    }

    const folderInfo = await frameIOApi(
      `/assets/${selectedFolder}`,
      userData?.refresh_token,
    )
    if (folderInfo.ok == 0) {
      openErrorNotification('bottomRight', folderInfo.message)
      return
    }

    setIsLoading(true)
    form
      .validateFields() // Validate the form fields
      .then((values) => {
        let data = {
          destination_name: selectedFolderName,
          destination_id: selectedFolder,
          title: values.title,
          description: values.description,
          url: urlPath,
          id: selectedEditUpload.id,
        }

        axios
          .post(
            process.env.REACT_APP_UPLOAD_API_BASEPATH + '/uploads/update',
            data,
          )
          .then((response) => {
            if (
              response.data &&
              response.data.type &&
              response.data.type == 'success'
            ) {
              getUploadsDataWithUserId()
              setUploadId(response.data.data.id)
              setCopied(false)
              setIsSwitchEnabled(!!response.data?.data?.embded)
              setCreateSubfolder(!!response.data?.data?.tsfolder)
              setShowShareModal(true)
              closeUploadEditModal()
              setIsLoading(false)
            } else {
              setIsLoading(false)
              openErrorNotification('bottomRight', response.data?.message)
            }
          })
          .catch((error) => {
            setIsLoading(false)
            openErrorNotification('bottomRight')
            console.error('Error:', error)
          })
      })
      .catch((error) => {
        setIsLoading(false)
        // Form validation failed, handle the error and display messages
        console.log('Form validation error:', error)
        // message.error('Please fill in all required fields.')
      })
  }

  //Switch
  const onChange = (checked, field) => {
    setIsLoading(true)

    if (field === 'embded') {
      setIsSwitchEnabled(checked)
    } else {
      setCreateSubfolder(checked)
    }
    let data = {
      value: checked,
      field,
      id: uploadId,
    }

    axios
      .post(
        process.env.REACT_APP_UPLOAD_API_BASEPATH + '/uploads/updatePreference',
        data,
      )
      .then((response) => {
        if (
          response.data &&
          response.data.type &&
          response.data.type == 'success'
        ) {
          getUploadsDataWithUserId()
          setIsLoading(false)
        } else {
          setIsLoading(false)
          // getAccounts()
        }
      })
      .catch((error) => {
        setIsLoading(false)
        console.error('Error:', error)
      })
  }

  const handleCopyClick = () => {
    if (!props.isPaidUser) {
      message.error('Please purchase a subscription to proceed.')
      return
    }

    const urlToCopy = uploadurl + uploadId
    cboard.copy(urlToCopy).then(() => {
      setCopied(true)
    })
  }

  const handleCopyIframeClick = () => {
    const embedCode = `<iframe src="${uploadurl + uploadId
      }" title="Embedded Page" width="100%" height="500px" />`
    handleCopy(embedCode)
  }

  const handleCopyEmbeddedLinkClick = (id) => {
    const embedCode = `<iframe src="${uploadurl + id
      }" title="Embedded Page" width="100%" height="500px" />`
    handleCopy(embedCode)
  }

  return (
    <div>
      <div className="home-list mb-0">
        {uploadsData && uploadsData.length > 0 ? (
          uploadsData.map((upload, index) => (
            <div
              className="list-card pl-15 pr-16 pt-10 pb-10 mb-10"
              key={index ? index : null}
            >
              <div className="flex">
                <h3 className="app-modal-p mb-0">{upload.title}</h3>
                <div className="list-actions">
                  {upload.embded ? (
                    <Button className="primary-button-icon">E</Button>
                  ) : null}
                  <DeleteOutlined
                    onClick={() => {
                      if (props.isPaidUser) {
                        setSelectedUpload(upload)
                        showModal()
                      } else {
                        message.error(
                          'Please purchase a subscription to proceed.',
                        )
                      }
                    }}
                  />
                  <EditOutlined
                    onClick={() => {
                      if (props.isPaidUser) {
                        setSelectedEditUpload(upload)
                        // getTeams()
                        getProjectsData()
                      } else {
                        message.error(
                          'Please purchase a subscription to proceed.',
                        )
                      }
                    }}
                  />
                </div>
              </div>
              <p className="mt-10 mb-10 app-xsm">{upload.description}</p>
              <span className="mb-5">
                <a
                  className="app-sidemenu-para-a"
                  href={`https://app.frame.io/projects/${upload.url}`}
                  target="_blank"
                >
                  Frame.io Path
                </a>
                <LinkOutlined
                  className="copy-icon"
                  style={{ marginLeft: '10px' }}
                  onClick={() => {
                    handleCopy('https://app.frame.io/projects/' + upload.url)
                  }}
                />
              </span>
              <br />
              <span className="mb-5">
                <a
                  className="app-sidemenu-para-a"
                  href={`${uploadurl + upload.id}`}
                  target="_blank"
                >
                  Upload Link
                </a>
                <LinkOutlined
                  className="copy-icon"
                  style={{ marginLeft: '10px' }}
                  onClick={() => {
                    handleCopy(uploadurl + upload.id)
                  }}
                />
              </span>
              <br />

              {upload.embded ? (
                <span className="mb-5">
                  <a
                    className="app-sidemenu-para-a"
                    style={{ cursor: 'default' }}
                  >
                    Embedded Code
                  </a>
                  <LinkOutlined
                    className="copy-icon"
                    style={{ marginLeft: '10px' }}
                    onClick={() => {
                      handleCopyEmbeddedLinkClick(upload.id)
                    }}
                  />
                </span>
              ) : null}

              <p className="mt-5 app-xsm">
                Usage: {upload.usages ? upload.usages : 0} uploads,{' '}
                {upload.uploads ? upload.uploads : 0} files
              </p>
            </div>
          ))
        ) : (
          <div
            className="flex-with-fixed-height-no-uploads no-uploads-sec"
            style={{ width: '100%' }}
          >
            <div className="home-card mb-20" style={{ width: '88%' }}>
              <h4 className="app-sidemenu-para">No Uploads.</h4>
              <h4 className="app-sidemenu-para">
                Please click on "Generate Link" to create an upload.
              </h4>
            </div>
          </div>
        )}

        {contextHolder}
        {isLoading ? <Loading /> : null}
        {/* <div className="list-card pl-15 pr-16 pt-10 pb-10">
          <div className="flex">
            <h3 className="app-modal-p ">SiloSync</h3>
            <div className="list-actions">
              <Button className="primary-button-icon">E</Button>
              <DeleteOutlined onClick={showModal} />
              <EditOutlined />
            </div>
          </div>
          <p className="mt-0 mb-0 app-xsm">Files Sent To Frame.IO</p>
          <span>
            <a className="mb-5 app-sidemenu-para-a">www.frame.io/alex/silosync</a>
            <LinkOutlined
              className="copy-icon"
              style={{ marginLeft: '10px' }}
              onClick={handleCopy}
            />
          </span>
          <p className="mt-5 app-xsm">Usage: 5 uploads, 20 files</p>
        </div>
        <div className="list-card mt-20 pl-15 pr-16 pt-10 pb-10">
          <div className="flex">
            <h3 className="app-modal-p ">Upload Link Name</h3>
            <div className="list-actions">
              <DeleteOutlined onClick={showModal} />
              <EditOutlined />
            </div>
          </div>
          <p className="mt-0 mb-0 app-xsm">Description</p>
          <span><a className="mb-5 app-sidemenu-para-a">Upload Path On Frame.IO</a><LinkOutlined className="copy-icon" onClick={handleCopy} style={{ marginLeft: "10px" }} /> </span>
          <p className="mt-5 app-xsm">Usage: 0 uploads, 0 files</p>
        </div> */}

        {/* Connect to Frmeio Modal */}
        <Modal open={isModalOpen}>
          <div className="text-right">
            <CloseCircleOutlined onClick={handleClose} className="close-icon" />
          </div>
          <div className="block text-center">
            <DeleteOutlined className="delete-icon" />
          </div>
          <p className="app-modal-p text-center mt-20 mb-20">
            Are you sure <br /> you want to delete?
          </p>
          {/* <p className="app-modal-p text-center mt-10 mb-20">
          Do you really want to delete
          </p> */}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button className="primary-modal-button" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              className="primary-modal-button ml-10"
              style={{ minWidth: '80px' }}
              onClick={() => deleteUpload()}
            >
              Yes
            </Button>
          </div>
        </Modal>

        {/* upload Edit modal  */}
        {showUploadEditModal ? (
          <Modal open={showUploadEditModal}>
            <div className="flex">
              <h2 className="app-md-font" style={{ fontWeight: '700' }}>
                Edit
              </h2>
              <CloseCircleOutlined
                onClick={() => {
                  closeUploadEditModal()
                }}
                className="close-icon"
              />
            </div>
            <div
              className="mt-20"
              key={
                selectedUpload && selectedUpload.id ? selectedUpload.id : null
              }
            >
              <Form
                form={form}
                layout="vertical"
                initialValues={selectedUpload}
              >
                <Form.Item
                  className="app-label"
                  label="Title"
                  name="title"
                  rules={[
                    { required: true, message: 'Please enter your Title' },
                  ]}
                >
                  <Input className="app-input" />
                </Form.Item>
                <Form.Item
                  className="app-label"
                  label="Shot Notes / Description"
                  name="description"
                  rules={[{ message: 'Please enter your Description' }]}
                >
                  <Input.TextArea className="app-textarea" rows={4} />
                </Form.Item>
                <Form.Item>
                  <Form.Item
                    className="app-label"
                    label="Destination Folder"
                    name="destination_name"
                    icon={<EditOutlined />}
                    rules={[
                      {
                        required: false,
                        message: 'Please select your Destination Folder',
                      },
                    ]}
                    disabled
                  >
                    <Input
                      className="app-input"
                      disabled
                      suffix={
                        <EditOutlined
                          onClick={() => {
                            setShowDestinationFolderModel(true)
                          }}
                        />
                      }
                    />
                  </Form.Item>

                  <div className="flex">
                    <Button
                      className="primary-modal-button"
                      onClick={() => {
                        closeUploadEditModal()
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="primary-modal-button"
                      onClick={() => {
                        updateUploadData()
                      }}
                    >
                      Update
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </Modal>
        ) : null}

        {/* select destinationFolder */}
        {showDestinationFolderModel ? (
          <Modal open={showDestinationFolderModel}>
            <div className="flex">
              <h2 className="app-select-heading">Select Upload Destination</h2>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ReloadOutlined
                  className="reload-icon"
                  onClick={() => {
                    // getTeams()
                    getProjectsData()
                  }}
                />
                <CloseCircleOutlined
                  onClick={closeDestinationFolderModel}
                  className="close-icon"
                />
              </div>
            </div>

            {treeData.length > 0 ? (
              <>
                <div className="tree-section-main pt-10 pl-10">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src="/assets/images/frame.png" width="30px" />
                    <span className="frame-label ml-10">Frame.IO</span>
                  </div>
                  <Tree
                    key={treeData ? treeData : null}
                    onSelect={onSelect}
                    treeData={treeData}
                    loadData={onLoadData}
                    defaultExpandedKeys={defaultOpenKeys}
                    switcherIcon={<DownOutlined className="switcher-icon" />}
                    id="style-2"
                  />
                </div>
              </>
            ) : (
              <>
                {treeLoading ? (
                  <div className="text-center app-modal-p">
                    Loading tree data...
                  </div>
                ) : null}
              </>
            )}

            <div></div>
          </Modal>
        ) : null}

        {/* Share Modal        */}
        {showShareModal && uploadId ? (
          <Modal open={showShareModal} width={700}>
            <div className="text-right mb-0">
              <CloseCircleOutlined
                onClick={() => {
                  setShowShareModal(false)
                }}
                className="close-icon"
              />
            </div>
            <div>
              <h4 className="app-modal-p mb-10 mt-0">Share</h4>
              <div className="share-card pt-10 pb-10 pl-20 pr-10 text-center">
                <h4 className="app-modal-p mb-0 mt-0">URL Upload Link</h4>
                <a className="mb-20 pb-0">
                  {uploadurl}
                  {uploadId}
                </a>
              </div>
              <Button
                className="primary-modal-button block mt-10"
                onClick={handleCopyClick}
              >
                {copied ? 'Link Copied!' : 'Copy Link'}
              </Button>
              <div className="embed-div">
                <h4 className="app-modal-p mb-15">Embed</h4>
                <label className="app-upload-text cursor">
                  <Switch
                    className="mr-10"
                    defaultChecked={isSwitchEnabled}
                    onChange={(e) => {
                      onChange(e, 'embded')
                    }}
                  />
                  Enable Embed
                </label>
                {isSwitchEnabled && (
                  <Space.Compact
                    style={{
                      width: '100%',
                    }}
                  >
                    <Input
                      placeholder="iframe src = “https:"
                      value={`<iframe src="${uploadurl + uploadId
                        }" title="Embedded Page" width="100%" height="500px" />`}
                      className="app-input-modal mt-20"
                    />
                    <Button
                      className="primary-modal-button mt-20"
                      onClick={handleCopyIframeClick}
                    >
                      Copy Code
                    </Button>
                  </Space.Compact>
                )}

                <h4 className="app-modal-p mb-15">Folder Options</h4>
                <label className="app-upload-text cursor">
                  <Switch
                    className="mr-10"
                    defaultChecked={createSubfolder}
                    onChange={(e) => {
                      onChange(e, 'ts_folder')
                    }}
                  />
                  Create Timestamp Subfolder For Each Upload
                </label>
              </div>
            </div>
          </Modal>
        ) : null}
      </div>
    </div>
  )
})
export default List
