export const SET_INITIAL_DATA = "SET_INITIAL_DATA"
export const SET_CURRENT_USER_DATA = "SET_CURRENT_USER_DATA"

export const setInitialData = (data) => ({
    type: SET_INITIAL_DATA,
    data
})

export const setUserData = (data) => ({
    type: SET_CURRENT_USER_DATA,
    data
})
