import * as React from 'react'
import * as actions from "./actions"


const SiloContext = React.createContext()

const reducer = (state, action) => {
    switch (action.type) {
        case actions.SET_INITIAL_DATA:
            const { ordersList, userData } = action.data || {}
            return { ...state, paymentsData: (Array.isArray(ordersList) ? ordersList : []), wixUser: userData || {} }

        case actions.SET_CURRENT_USER_DATA:
            return { ...state, userData: action.data || {} }

        default:
            return { ...state }
    }
}

export function useSelector(selectorCallback) {
    const { state } = React.useContext(SiloContext)
    return selectorCallback(state)
}

export function useDispatch() {
    const ctx = React.useContext(SiloContext)
    return (action) => {
        ctx.dispatch(action)
    }
}

const defaultStoreState = {
    paymentsData: [],
    userData: {},
    wixUser: {}
}

function SiloCtxProvider({ defaultState = {}, children }) {
    const [state, dispatch] = React.useReducer(reducer, { ...defaultStoreState, ...defaultState })

    const value = { state, dispatch }
    return <SiloContext.Provider value={value}>{children}</SiloContext.Provider>
}

export default SiloCtxProvider
