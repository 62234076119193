import React, { useState, useEffect, useRef } from 'react'
import { Col, Row } from 'antd'
import { message, Upload } from 'antd'
import {
  Button,
  Modal,
  notification,
  Form,
  Input,
  Switch,
  Space,
  Tree,
} from 'antd'
import {
  DownOutlined,
  CloseCircleOutlined,
  ReloadOutlined,
  EditOutlined,
} from '@ant-design/icons'

import axios from 'axios'
import List from '../../components/list/list'
import Loading from '../../components/loading/loading'
import { generateRandomState } from '../../utils/strings'
import { withUser } from '../../HoC/withUser'
import cboard from '../../utils/clipboard'
import { getFrameioFolderPath } from '../../utils/frameio'
import EmailPreferences from '../../components/email-preferences'

import "./home.scss"

//FileUpload
const getBase64 = (img, callback) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!')
  }
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!')
  }
  return isJpgOrPng && isLt2M
}

const frameIOClientId = process.env.REACT_APP_FRAME_IO_CLIENT_ID
const frameIORedirectUrl = process.env.REACT_APP_FRAME_IO_REDIRECT_URL
const apiBasePath = process.env.REACT_APP_UPLOAD_API_BASEPATH
const uploadurl = process.env.REACT_APP_UPLOAD_UI_BASEPATH + '/upload/'

function Home(props) {
  const [isSwitchEnabled, setIsSwitchEnabled] = useState(false)
  const [createSubfolder, setCreateSubfolder] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  //Modal
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModelVisible, setModelVisible] = useState(false)
  const [showNextModal, setNextShowModal] = useState(false)
  const [showShareModal, setShowShareModal] = useState(false)
  //Notification
  const [api, contextHolder] = notification.useNotification()
  const [treeData, setTreeData] = useState([])
  const [defaultOpenKeys, setDefaultOpenKeys] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null)
  const [selectedFolderName, setSelectedFolderName] = useState(null)
  const [urlPath, setUrlPath] = useState(null)
  const [uploadId, setUploadId] = useState(null)
  const [form] = Form.useForm()
  const [treeLoading, setTreeLoading] = useState(null)
  const [selectedObj, setSelectedObj] = useState(null)
  const [isEditModal, setIsEditModal] = useState(null)
  const [copied, setCopied] = useState(false)

  const { paymentsData = [], userData } = props

  const isPaidUser = React.useMemo(() => {
    try {
      return paymentsData.find((payment) => payment.status === 'ACTIVE')
        ? true
        : false
    } catch (e) {
      console.log('home/paidUser_useMemo', e)
      return false
    }
  }, [paymentsData])

  const listRef = useRef(null)

  useEffect(() => {
    handleRedirect()
    getUserData()
    // getSharedProjectIds()

    document.body.classList.add("home-body");


    return () => {
      document.body.classList.remove("home-body");
    }
  }, [])

  useEffect(() => {

    // getSharedProjectIds()
    // This effect will trigger whenever selectedFolder or selectedFolderName changes
    if (userData.id && userData.access_token) {
      getProjectsData()
    }
  }, [userData.access_token])

  const getUserData = async () => {
    props
      .getUserData()
      .then(() => {
        setTreeData([])
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const handleClose = () => {
    setIsModalOpen(false)
  }

  const openModel = () => {
    if (userData && userData.owner_email) {
      setSelectedObj(null)
      setSelectedFolderName(null)
      setUrlPath(null)
      setSelectedFolder(null)
      setModelVisible(true)
    } else {
      openNotification('bottomRight')
    }
  }

  const closeModel = () => {
    setModelVisible(false)
    setTreeData([]) // Reset the treeData to an empty array
    // getTeams()
    getProjectsData()
  }

  const openNextModal = () => {
    setNextShowModal(true)
  }

  const closeNextModal = () => {
    setNextShowModal(false)
    setIsEditModal(null)
    form.resetFields()
  }

  const handleNextClick = () => {
    if (isEditModal) {
      setIsEditModal(null)
      form.setFieldsValue({
        destination_name: selectedFolderName,
      })
    } else {
      openNextModal()
      setIsEditModal(null)
      form.setFieldsValue({
        title: '',
        description: '',
        destination_name: selectedFolderName,
      })
    }
  }

  // const openShareModal = () => {
  //   setNextShowModal(true)
  // }

  const closeShareModal = () => {
    setShowShareModal(false)
    if (listRef.current) {
      listRef.current.getUploadsDataWithUserId()
    }
  }

  // const handleShareClick = () => {
  //   openShareModal()
  // }

  // const handleDivClick = () => {
  //   setIsDivClicked((prevState) => !prevState)
  // }

  //Notification
  const openNotification = (placement) => {
    api.info({
      message: `Connection Error!`,
      description: 'Please connect to Frame.io account.',
      placement,
      className: 'custom-notification-bar',
      style: {
        width: 500,
      },
      duration: 10000,
      closeIcon: <CloseCircleOutlined />,
    })
  }

  //Switch
  const onChange = (checked, field) => {
    setIsLoading(true)

    if (field === 'embded') {
      setIsSwitchEnabled(checked)
    } else {
      setCreateSubfolder(checked)
    }

    let data = {
      value: checked,
      field,
      id: uploadId,
    }

    axios
      .post(apiBasePath + '/uploads/updatePreference', data)
      .then((response) => {
        if (
          response.data &&
          response.data.type &&
          response.data.type === 'success'
        ) {
          setIsLoading(false)
        } else {
          setIsLoading(false)
          // getAccounts()
        }
      })
      .catch((error) => {
        setIsLoading(false)
        console.error('Error:', error)
      })
  }

  //FileUpload
  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      // setLoading(true)
      return
    }
    if (info.file.status === 'done') {
      // Get this url from response in the real world.
      getBase64(info.file.originFileObj, (/* url */) => {
        // setLoading(false)
        // setProfilePic(url)
      })
    }
  }

  const signIn = () => {
    var state = generateRandomState(8)
    var authorizeUrl = `https://applications.frame.io/oauth2/auth?client_id=${frameIOClientId}&redirect_uri=${frameIORedirectUrl}&response_type=code&scope=account.read team.read project.read asset.read asset.create asset.update asset.delete offline&state=${state}`
    window.location.href = authorizeUrl
  }

  const handleRedirect = () => {
    const searchParams = new URLSearchParams(window.location.search)
    var code = searchParams.get('code')
    var scope = searchParams.get('scope')

    if (code) {
      setIsLoading(true)
      const serverUrl = apiBasePath + '/callback'
      const data = new URLSearchParams()
      data.append('code', code)
      data.append('scope', scope)
      axios
        .post(serverUrl, data)
        .then((response) => {
          if (
            response.data &&
            response.data.type &&
            response.data.type == 'success'
          ) {
            props.handleUserData(response.data.data)
          } else {
            openNotification('bottomRight')
          }
        })
        .catch((error) => {
          openNotification('bottomRight')
          console.error('Error:', error)
        })
        .finally(() => {
          window.history.replaceState(
            {},
            document.title,
            window.location.pathname,
          )

          setIsLoading(false)
        })
    }
  }


  const getSharedProjectIds = async () => {
    const token = userData ? userData.refresh_token : null;

    if (!token) {
      setTreeLoading(false);
      return [];
    }

    try {
      const response = await axios.get('https://api.frame.io/v2/projects/shared', {
        params: { accountId: userData.id },
        headers: { Authorization: `Bearer ${token}` },
      });

      const projects = response.data;

      // Ensure projects is an array
      if (Array.isArray(projects)) {
        // Fetch details for each project and collect the results
        const projectData = await Promise.all(projects.map(async (project) => {
          return getCollabrationProject(project.id, {
            teamId: project.team?.id || project.team_id,
            teamName: project.team?.name || ""
          });
        }));

        // Flatten the array of arrays
        return projectData.flat();
      } else {
        console.error('Expected an array of shared projects but received:', projects);
      }
    } catch (error) {
      console.error("Error fetching shared projects:", error);
    } finally {
      // setTreeLoading(false);
    }
    return [];
  };


  const getCollabrationProject = async (projectID, { teamId = '', teamName = '' } = {}) => {
    const token = userData ? userData.refresh_token : null;

    if (token) {
      try {
        const response = await axios.get(`https://api.frame.io/v2/projects/${projectID}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = response.data;

        if (data) {
          // Return an array containing the single project object
          return [{
            id: teamId,
            root_asset_id: teamId,
            title: teamName,
            key: teamId,
            project_id: "",
            type: "team",
            children: [{
              id: data.id,
              root_asset_id: data.root_asset_id,
              title: data.name,
              key: data.root_asset_id,
              project_id: data.id,
              assets: [],
            }]
          }];
        } else {
          console.error('Expected project data but received:', data);
        }
      } catch (error) {
        console.error('Error fetching project details:', error);
      }
    }
    return [];
  };

  const getTeamsMembers = async () => {
    const token = userData ? userData.refresh_token : null;
    try {
      const response = await axios.get('https://api.frame.io/v2/teams', {
        headers: { Authorization: `Bearer ${token}` },
      });

      const accountIds = [...new Set(response.data.map((team) => team.account_id))];

      // Fetch teams for each accountId
      const teamsData = await Promise.all(
        accountIds.map((id) => getTeams(id, token))
      );

      // Flatten the array and remove any null values
      return teamsData.flat().filter(data => data !== null);
    } catch (error) {
      console.error('Error fetching teams:', error);
      // setError('Error fetching teams');
    } finally {
      // setLoading(false);
    }
    return []; // Ensure this is always an array
  };


  const getTeams = async (accountId, token) => {
    setTreeData([]);
    setTreeLoading(true);

    if (!token) {
      setTreeLoading(false);
      return [];
    }

    try {
      const response = await axios.get(`https://api.frame.io/v2/accounts/${accountId}/teams`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data && response.data.length > 0) {
        const teamsList = response.data;
        const projectsData = await Promise.all(teamsList.map(async (team) => {
          return getProjects(team.id, team.name, token);
        }));

        return projectsData.flat();
      }
    } catch (error) {
      console.error('Error fetching teams:', error);
    } finally {
      setTreeLoading(false);
    }
    return []; // Ensure this is always an array
  };

  const getProjects = async (teamId, teamName, token) => {
    if (!token) {
      return [];
    }

    try {
      const response = await axios.get(`https://api.frame.io/v2/teams/${teamId}/projects`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data) {
        const projects = response.data;
        return [{
          id: teamId,
          root_asset_id: teamId,
          key: teamId,
          assets: [],
          value: teamId,
          projectId: "",
          type: "team",
          title: teamName,
          children: projects.map((item) => ({
            id: item.id,
            root_asset_id: item.root_asset_id,
            title: item.name,
            type: "folder",
            key: item.root_asset_id,
            project_id: item.id,
            assets: [],
          }))
        }];
      }
    } catch (error) {
      console.error('Error fetching projects for team:', error);
    }
    return [];
  };

  const getProjectsData = async () => {
    setTreeLoading(true);
    try {
      const [sharedProjects, regularProjects] = await Promise.all([
        getTeamsMembers(),
        getSharedProjectIds()
      ]);

      if (!Array.isArray(regularProjects)) {
        throw new Error("regularProjects is not an array");
      }

      const defaultOpenKeys = [];

      const allProjects = [...sharedProjects, ...regularProjects].map((treeItem) => {
        defaultOpenKeys.push(treeItem.key)
        return treeItem;
      });

      setTreeData(allProjects);
      setDefaultOpenKeys(defaultOpenKeys)
    } catch (error) {
      console.error('Error fetching projects data:', error);
    } finally {
      setTreeLoading(false);
    }
  };

  const getFolderPath = (selectedKeys) => {
    let folderId = ''
    if (Array.isArray(selectedKeys)) {
      folderId = selectedKeys[0] || ''
    } else if (typeof selectedKeys == 'object') {
      folderId = selectedKeys?.value || ''
    } else if (typeof selectedKeys == 'string') {
      folderId = selectedKeys
    }

    return getFrameioFolderPath(folderId, treeData)
  }

  const onSelect = (selectedKeys, info) => {
    if (info.node?.type === "team") {
      return;
    }

    if (info.selected) {
      setSelectedObj(info)
      if (
        info &&
        info.selectedNodes &&
        info.selectedNodes[0] &&
        info.selectedNodes[0].title
      ) {
        if (info.selectedNodes[0].id === info.selectedNodes[0].project_id) {
          setUrlPath(info.selectedNodes[0].project_id)
        } else {
          setUrlPath(
            info.selectedNodes[0].project_id + '/' + info.selectedNodes[0].id,
          )
        }

        setSelectedFolderName(getFolderPath(selectedKeys))
        setSelectedFolder(selectedKeys[0])
        setModelVisible(false)
      }
    } else {
      if (
        selectedObj &&
        selectedObj.selectedNodes &&
        selectedObj.selectedNodes[0] &&
        selectedObj.selectedNodes[0].title
      ) {
        if (
          selectedObj.selectedNodes[0].id ==
          selectedObj.selectedNodes[0].project_id
        ) {
          setUrlPath(selectedObj.selectedNodes[0].project_id)
        } else {
          setUrlPath(
            selectedObj.selectedNodes[0].project_id +
            '/' +
            selectedObj.selectedNodes[0].id,
          )
        }
        setSelectedFolderName(selectedObj.selectedNodes[0].title)
        setSelectedFolder(selectedObj.selectedNodes[0].key)
      }
    }
  }

  useEffect(() => {
    // This effect will trigger whenever selectedFolder or selectedFolderName changes
    if (selectedFolder && selectedFolderName) {
      handleNextClick()
    }
  }, [selectedFolder, selectedFolderName])

  const onLoadData = async ({ key, children, expanded }) => {
    if (children && expanded) {
      return
    }
    const updatedTreeData = await updateTreeData(treeData, key)
    setTreeData(updatedTreeData)
  }


  const updateTreeData = async (list, key) => {
    let nodeFound = false
    const updatedList = await Promise.all(
      list.map(async (node) => {
        if (node.key === key && node.type !== 'team') {
          nodeFound = true // Mark the node as found
          var token = userData ? userData.refresh_token : null
          if (token) {
            const response = await axios.get(
              `https://api.frame.io/v2/assets/${key}/children?type=folder`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            )

            if (response.data) {
              let children = []
              if (response.data.length > 0) {
                response.data.forEach((res) => {
                  children.push({
                    title: res.name,
                    id: res.id,
                    key: res.id,
                    project_id: node.project_id,
                  })
                })
              }

              return {
                ...node,
                children,
              }
            }
          }
        }

        if (node.children) {
          // If the node is already found, return the original node
          if (nodeFound) {
            return node
          }

          const updatedChildren = await updateTreeData(node.children, key)
          return {
            ...node,
            children: updatedChildren,
          }
        }

        return node
      }),
    )

    return updatedList
  }

  const onLogoUpload = (info) => {
    let userData = localStorage.getItem('siloUserData')
      ? localStorage.getItem('siloUserData')
      : null
    if (userData) {
      setIsLoading(true)
      const form = new FormData()
      form.append('file', info)
      form.append('id', userData)
      axios
        .post(apiBasePath + '/upload-profile-picture', form)
        .then((response) => {
          if (
            response.data &&
            response.data.type &&
            response.data.type == 'success'
          ) {
            props.handleUserData(response.data.data)
            setIsLoading(false)
          } else {
            setIsLoading(false)
            // getAccounts()
          }
        })
        .catch((error) => {
          setIsLoading(false)
          console.error('Error:', error)
        })
    }
  }

  const onRemoveLogo = () => {
    let userData = localStorage.getItem('siloUserData')
      ? localStorage.getItem('siloUserData')
      : null
    if (userData) {
      setIsLoading(true)
      axios
        .get(apiBasePath + `/deleteProfilePic/${userData}`)
        .then((response) => {
          if (
            response.data &&
            response.data.type &&
            response.data.type == 'success' &&
            response.data.data
          ) {
            props.handleUserData(response.data.data)
            setIsLoading(false)
          } else {
            setIsLoading(false)
          }
        })
        .catch((error) => {
          setIsLoading(false)
          console.error('Error:', error)
        })
    }
  }

  const handleGenerateClick = () => {
    setIsLoading(true)
    form
      .validateFields() // Validate the form fields
      .then((values) => {
        // Form validation passed, proceed with generation logic here
        let userData = localStorage.getItem('siloUserData')
          ? localStorage.getItem('siloUserData')
          : null
        if (userData) {
          const form = new FormData()
          form.append('destination_name', selectedFolderName)
          form.append('destination_id', selectedFolder)
          form.append('title', values.title)
          form.append('description', values.description)
          form.append('user_id', userData)
          form.append('url', urlPath)
          form.append('creator_wix_id', props.wixUser?._id || '')
          form.append('creator_wix_email', props.wixUser?.email || '')
          axios
            .post(apiBasePath + '/save-uploader-link', form)
            .then((response) => {
              if (
                response.data &&
                response.data.type &&
                response.data.type == 'success'
              ) {
                if (listRef.current) {
                  listRef.current.getUploadsDataWithUserId()
                }
                closeModel()
                setUploadId(response.data.data.id)
                setCopied(false)
                // setIsSwitchEnabled(false)
                setShowShareModal(true)
                // setIsSwitchEnabled(false)
                closeNextModal()
                setIsLoading(false)
              } else {
                setIsLoading(false)
                // getAccounts()
              }
            })
            .catch((error) => {
              setIsLoading(false)
              console.error('Error:', error)
            })
        } else {
          setIsLoading(false)
        }
      })
      .catch((error) => {
        setIsLoading(false)
        // Form validation failed, handle the error and display messages
        console.log('Form validation error:', error)
        // message.error('Please fill in all required fields.')
      })
  }

  const uploadButton = (
    <div>
      <div className="app-upload-text">Your logo here</div>
      <img src="/assets/images/upload-edit.png" className="edit-upload" />
    </div>
  )

  // const handleClearUserData = () => {
  //   setUserData(null)
  //   localStorage.clear()
  // }

  const handleCopyClick = () => {
    const urlToCopy = uploadurl + uploadId
    cboard.copy(urlToCopy).then(() => {
      setCopied(true)
    })
  }

  const handleCopyIframeClick = () => {
    const embedCode = `<iframe src="${uploadurl + uploadId
      }" title="Embedded Page" width="100%" height="500px" />`

    cboard
      .copy(embedCode)
      .then(() => {
        message.success('Copied!')
      })
      .catch(() => {
        message.error('Failed to copy!')
      })
  }

  return (
    <div className="app-main">
      <div className="home-main">
        <Row gutter={16} className="ml-0 mr-0 border list-container">
          <Col
            className="gutter-row home-left-side-col"
            xs={24}
            sm={24}
            md={12}
            lg={8}
          >
            <div className="uploadfile-section">
              <div
                className="logo-main"
                key={userData && userData.url ? userData.url : null}
              >
                {userData && userData.url ? (
                  <>
                    <div className="upload-logo-main">
                      <img
                        src={userData.url}
                        alt="avatar"
                        style={{
                          width: 200,
                          height: 200,
                        }}
                      />
                      <img
                        src="/assets/images/del-log.png"
                        className="edit-upload-user cursor"
                        onClick={() => onRemoveLogo()}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="default-logo">
                      <img
                        src="/assets/images/log-new.png"
                        alt="avatar"
                        style={{
                          width: 200,
                          height: 200,
                        }}
                      />
                    </div>
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      action={onLogoUpload}
                      beforeUpload={beforeUpload}
                      onChange={handleChange}
                      disabled={userData && userData.owner_email ? false : true}
                    >
                      <img
                        src="/assets/images/upload-edit.png"
                        className="edit-upload"
                      />
                    </Upload>
                  </>
                )}
              </div>

              {userData && userData.url ? null : (
                <p className="app-upload-text-white mt-10">Custom Branding</p>
              )}
              <p className="app-upload-text-white mt-10">200X200</p>
              {isLoading ? <Loading /> : null}
              {userData && userData.owner_email ? (
                <>
                  <p className="app-upload-text-white mt-30">
                    Connected to {userData.owner_email}{' '}
                  </p>
                  <p className="app-upload-text-white mt-0">
                    {' '}
                    Frame.io account successfully.
                  </p>
                </>
              ) : null}
            </div>
          </Col>
          <Col
            className="gutter-row home-right-side-col pt-30"
            xs={24}
            sm={24}
            md={12}
            lg={16}
          >
            <div className="home-section-right-card pt-20 pb-20" id="style-2">
              <div className="width-90 groups-list">
                <div className="flex mb-20">
                  <h1 className="app-heading-main">My Links</h1>
                  <Button
                    className="primary-button-md"
                    onClick={() => {
                      if (isPaidUser) {
                        openModel()
                      } else {
                        message.error(
                          'Please purchase a subscription to proceed.',
                        )
                      }
                    }}
                  >
                    Generate Link
                  </Button>
                </div>

                {userData && userData.owner_email ? (
                  <>
                    <div className="list-display" id="style-2">
                      <List
                        ref={listRef}
                        wixUser={props.wixUser}
                        user={userData}
                        foldersList={treeData}
                        isPaidUser={isPaidUser}
                      />
                    </div>
                  </>
                ) : (
                  <div className="flex-with-fixed-height no-uploads-container">
                    {contextHolder}

                    <div className="home-card">
                      <h4 className="app-sidemenu-para">
                        Please connect to Frame.io to proceed
                      </h4>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={24} sm={24} md={24} lg={24}>
            <EmailPreferences />
          </Col>
        </Row>

        {/* Connect to Frmeio Modal */}
        <div className="home-modal-container">
          <Modal
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            classNames={{
              mask: "home-modal-mask"
            }}
          >
            <div style={{ display: 'flex' }}>
              <div className="width-60">
                <img
                  src="/assets/images/s-logo-new.png"
                  style={{ textAlign: 'center' }}
                  className="ml-20"
                />
              </div>
              <CloseCircleOutlined
                onClick={handleClose}
                className="close-icon"
              />
            </div>
            <p className="app-modal-p text-center mt-20">
              Sign in to publish directly to Frame.io
            </p>
            <Button
              className="primary-button-md block mt-20"
              onClick={() => {
                signIn()
              }}
            >
              Sign in
            </Button>
          </Modal>
        </div>

        {isModelVisible ? (
          <div className="home-modal-container">
            <Modal open={isModelVisible} classNames={{
              body: "share-modal",
              mask: "home-modal-mask"
            }}>
              <div className="flex">
                <h2 className="app-select-heading upload-select">
                  Select Upload Destination
                </h2>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ReloadOutlined
                    className="reload-icon"
                    onClick={() => {
                      // getTeams()
                      getProjectsData()
                    }}
                  />
                  <CloseCircleOutlined
                    onClick={closeModel}
                    className="close-icon"
                  />
                </div>
              </div>

              {treeData.length > 0 ? (
                <>
                  <div className="tree-section-main pt-10 pl-10">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img src="/assets/images/frame.png" width="30px" />
                      <span className="frame-label ml-10">Frame.io</span>
                    </div>
                    <Tree
                      key={treeData ? treeData : null}
                      onSelect={onSelect}
                      treeData={treeData}
                      loadData={onLoadData}
                      defaultExpandedKeys={defaultOpenKeys}
                      switcherIcon={<DownOutlined className="switcher-icon" />}
                      id="style-2"
                    />
                  </div>
                </>
              ) : (
                <>
                  {treeLoading ? (
                    <div className="text-center app-modal-p">
                      Loading tree data...
                    </div>
                  ) : null}
                </>
              )}

              <div></div>
            </Modal>
          </div>
        ) : null}

        {/* From select modal  */}
        {showNextModal ? (
          <div className="home-modal-container">
            <Modal
              open={showNextModal}
              key={selectedFolderName ? selectedFolderName : null}
              classNames={{
                mask: "home-modal-mask"
              }}
            >
              <div className="flex">
                <h2 className="app-md-font" style={{ fontWeight: '700' }}>
                  Generate Upload Link
                </h2>
                <CloseCircleOutlined
                  onClick={() => {
                    closeNextModal()
                    setSelectedFolderName(null)
                    setSelectedFolder(null)
                    setUrlPath(null)
                  }}
                  className="close-icon"
                />
              </div>
              <div className="mt-20">
                <Form
                  form={form}
                  layout="vertical"
                  initialValues={{ destination_name: selectedFolderName }}
                >
                  <Form.Item
                    className="app-label"
                    label="Title"
                    name="title"
                    rules={[
                      { required: true, message: 'Please enter your Title' },
                    ]}
                  >
                    <Input className="app-input" />
                  </Form.Item>
                  <Form.Item
                    className="app-label"
                    label="Shot Notes / Description"
                    name="description"
                    rules={[{ message: 'Please enter your Description' }]}
                  >
                    <Input.TextArea className="app-textarea" rows={4} />
                  </Form.Item>
                  <Form.Item>
                    <Form.Item
                      className="app-label form-edit-item"
                      label="Destination Folder"
                      name="destination_name"
                      rules={[
                        {
                          required: false,
                          message: 'Please select your Destination Folder',
                        },
                      ]}
                      icon={<EditOutlined />}
                      disabled
                    >
                      <Input
                        className="app-input"
                        disabled
                        suffix={
                          <EditOutlined
                            onClick={() => {
                              setIsEditModal(true)
                              setModelVisible(true)
                            }}
                          />
                        }
                      />
                    </Form.Item>

                    <div className="flex">
                      <Button
                        className="primary-modal-button"
                        onClick={() => {
                          setSelectedFolderName(null)
                          setUrlPath(null)
                          setSelectedFolder(null)
                          closeNextModal()
                        }}
                      >
                        Back
                      </Button>
                      <Button
                        className="primary-modal-button"
                        onClick={handleGenerateClick}
                      >
                        Generate
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            </Modal>
          </div>
        ) : null}

        {/* Share Modal */}
        {showShareModal && uploadId ? (
          <div className="home-modal-container">
            <Modal open={showShareModal} width={700} classNames={{
              mask: "home-modal-mask"
            }}>
              <div className="text-right mb-0">
                <CloseCircleOutlined
                  onClick={closeShareModal}
                  className="close-icon"
                />
              </div>
              <div>
                <h4 className="app-modal-p mb-10 mt-0">Share</h4>
                <div className="share-card pt-10 pb-10 pl-20 pr-10 text-center">
                  <h4 className="app-modal-p mb-0 mt-0">URL Upload Link</h4>
                  <a className="mb-20 pb-0">
                    {uploadurl}
                    {uploadId}
                  </a>
                </div>
                <Button
                  className="primary-modal-button block mt-10"
                  onClick={handleCopyClick}
                >
                  {copied ? 'Link Copied!' : 'Copy Link'}
                </Button>
                <div className="embed-div">
                  <h4 className="app-modal-p mb-15">Embed</h4>
                  <label className="app-upload-text cursor">
                    <Switch
                      className="mr-10"
                      defaultChecked={isSwitchEnabled}
                      onChange={(e) => {
                        onChange(e, 'embded')
                      }}
                    />
                    Enable Embed
                  </label>
                  {isSwitchEnabled && (
                    <Space.Compact
                      style={{
                        width: '100%',
                      }}
                    >
                      <Input
                        placeholder="iframe src = “https:"
                        value={`<iframe src="${uploadurl + uploadId
                          }" title="Embedded Page" width="100%" height="500px" />`}
                        className="app-input-modal mt-20"
                      />
                      <Button
                        className="primary-modal-button mt-20"
                        onClick={handleCopyIframeClick}
                      >
                        Copy Code
                      </Button>
                    </Space.Compact>
                  )}

                  <h4 className="app-modal-p mb-15">Folder Options</h4>
                  <label className="app-upload-text cursor">
                    <Switch
                      className="mr-10"
                      defaultChecked={createSubfolder}
                      onChange={(e) => {
                        onChange(e, 'ts_folder')
                      }}
                    />
                    Create Timestamp Subfolder For Each Upload
                  </label>
                </div>
              </div>
            </Modal>
          </div>
        ) : null}
      </div>
    </div>
  )
}
export default withUser(Home)
