import React from "react"

import axios from "axios"

import { useDispatch, useSelector } from "../store"
import { setUserData } from "../store/actions"
import Loading from "../components/loading/loading"
import { postMessageToParent } from "../utils/parent"

const apiBasePath = process.env.REACT_APP_UPLOAD_API_BASEPATH


export function withUser(Wrappable) {
    return (props) => {
        const { paymentsData, userData, wixUser } = useSelector((state) => ({
            paymentsData: state.paymentsData || [],
            userData: state.userData || {},
            wixUser: state.wixUser || {}
        }))

        const [isLoading, setIsLoading] = React.useState(false)
        const dispatch = useDispatch()

        const handleUserData = (data) => {
            if (data.id) {
                localStorage.setItem('siloUserData', data.id)
            }
            dispatch(setUserData(data))
            postMessageToParent({ action: 'completed', data: data }, { targetOrigin: "*" })
        }

        const getUserData = async () => {
            try {
                if (userData.id && userData.access_token && userData.refresh_token) {
                    handleUserData(userData)
                }

                let siloUserId = localStorage.getItem('siloUserData')

                if (siloUserId === 'undefined') {
                    siloUserId = undefined
                    localStorage.removeItem('siloUserData')
                }

                if (siloUserId) {
                    setIsLoading(true)
                    const data = new URLSearchParams()
                    data.append('id', siloUserId)
                    const response = await axios.post(apiBasePath + '/getUserData', data)
                    handleUserData(response.data?.type === 'success' ? response.data.data : {})
                } else {
                    handleUserData({})
                }
            } catch (e) {
                console.log('withUser/getUserData', e)
                handleUserData({})
            } finally {
                setIsLoading(false)
            }
        }

        return (
            <>
                <Wrappable
                    {...props}
                    paymentsData={paymentsData}
                    userData={userData}
                    wixUser={wixUser}
                    getUserData={getUserData}
                    handleUserData={handleUserData}
                />
                {isLoading ? <Loading /> : null}
            </>
        )
    }
}