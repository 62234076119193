import React from "react"
import { Spin } from "antd"

import { LoadingOutlined } from "@ant-design/icons"

import "./SuspenseLoader.scss"


export const SuspenseLoader = ({ message = 'Loading...' }) => {
    return (
        <div className="suspense_loader">
            <Spin className="suspense_loader_spinner" indicator={<LoadingOutlined style={{ fontSize: 25 }} spin />} />
            {message}
        </div>
    )
}

export default (props) => (
    <React.Suspense fallback={<SuspenseLoader />}>
        {props.children}
    </React.Suspense>
)
